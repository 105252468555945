<div>
  <div class="grid grid-nogutter mb-3">
    <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
      <h1 class="h4 m-0">{{title}} Talent Pool</h1>
    </div>
  </div>
  <form [formGroup]="createForm">
    <div class="grid mt-2">
      <div class="col-12 lg:col-6 xl:lg:col-6 pb-3 pt-0 pl-0 pr-0 xl:pr-2">
        <p-floatLabel>
          <input formControlName="name" type="text" pInputText />
          <label for="name">Name</label>
        </p-floatLabel>
        <div class="pt-3">
          <p-floatLabel>
            <textarea id="description" formControlName="description" type="text" rows="5" pInputTextarea></textarea>
            <label for="description">Description</label>
          </p-floatLabel>
        </div>
        <div>
          <div class="switch-w-label pt-2 mt-2 flex gap-2">
            <label for="stfProject" class="line-height-2">Share with all {{firm?.name}} Managers?</label>
            <p-inputSwitch formControlName="isPublicToFirm" class="mpl-switch" inputId="stfProject"
              ariaLabel="My Projects" />
          </div>

        </div>
        <div class="flex justify-content-end">
          @if(!ref) {
            <p-button [routerLink]="'/client/people/talentPool/listing'" class="mr-2" label="BACK" [outlined]="true"
              [rounded]="true" size="large" />
          }
          <p-button label="NEXT" size="large" [disabled]="!createForm.valid" [rounded]="true" (onClick)="onSubmit()" />
        </div>
      </div>
    </div>

  </form>
</div>