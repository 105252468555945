import { Component, OnInit, Optional } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { PeopleService } from '../../../../../../shared/services/people.service';
import { UserService } from '../../../../../../shared/services/user.service';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-create',
  standalone: true,
  imports: [
    InputTextareaModule,
    InputSwitchModule,
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    RouterModule
  ], templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class CreateComponent implements OnInit {
  createForm!: FormGroup;
  buttonText: string = '';
  talentPoolId!: string | null;
  title!: string
  statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];
  firm: any = null;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private peopleService: PeopleService,
    private userService: UserService,
    private toast: ToastService,
    private router: Router,
    @Optional() public ref?: DynamicDialogRef
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.talentPoolId = this.route.snapshot.paramMap.get('talentPoolId');
    this.title = this.talentPoolId ? 'Edit' : 'Create';
    this.getTalentPoolDetails();
  }

  initializeForm() {
    this.firm = this.userService.getSessionUser().firm;
    this.createForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      isPublicToFirm: [true],
      firmId: [this.userService.getSessionUser().firm._id]
    });
  }
  
  onSubmit() {
    if (this.createForm.valid) {
      if (this.talentPoolId) {
        this.peopleService.editTalentPool(this.talentPoolId, this.createForm.value).subscribe((response: any) => {
          this.toast.showSuccess('Talent Pool Updated Successfully');
          this.navigateToAssignUsers(response._id);
        })
      } else {
        this.peopleService.addTalentPool(this.createForm.value).subscribe((response: any) => {
          this.toast.showSuccess('Talent Pool Added Successfully');
          if (this.ref) {
            this.ref.close();
          } else {
            this.navigateToAssignUsers(response._id);
          }
        })
      }
    }
  }

  getTalentPoolDetails() {
    if (this.talentPoolId) {
      this.peopleService.getTalentPoolById(this.talentPoolId).subscribe((response: any) => {
        this.createForm.patchValue(response);
      })
    }
  }

  navigateToAssignUsers(id: string) {
    this.router.navigateByUrl(`/client/people/talentPool/assign/${id}`);
  }
}

